<template>
  <module-container
    route-name="categories"
    show-toolbar
    :title="$t('categories')"
  >
    <template #route>
      <router-view />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component
export default class CategoriesIndex extends Mixins(PaginateProviderMixin) {}
</script>
